/* eslint-disable sonarjs/prefer-while */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import PropTypes from 'prop-types';

import { PAGES, TEMPLATE_TOKENS, ARTICLES_DESCRIPTION, MONTHS } from '../constants/Languages';
import { GLOBAL_CREDIT } from '../constants/SEOTokens';

import Breadcrumbs from '../components/Common/Breadcrumbs/Breadcrumbs';
import Sidebar from '../components/Common/Sidebar/Sidebar';
import PopularPages from '../components/Common/PopularPages/PopularPages';
import TagPage from '../components/Common/TagPage/TagPage';
import Voting from '../components/Common/Voting/Voting';
import layoutContext from '../components/layoutContext';
import Rubrics from '../constants/Rubrics';

const StockPost = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, htmlAst } = markdownRemark;
  const {
    title,
    headLine,
    path,
    lang,
    date,
    desc,
    canonicalUrl,
    disableTitlePostfix,
    disableDescriptionPostfix
  } = frontmatter;

  useContext(layoutContext).setCult(lang);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'eventCategory': 'article',
        'eventAction': 'view',
        'eventLabel': title,
        'eventNonInteraction': 'False',
        'event': 'autoEvent'
      });
    }
  }, []);

  const postRubrics = [];

  if (frontmatter.rubrics) {
    Object.keys(Rubrics).forEach(key => {
      frontmatter.rubrics.forEach(rubric => {
        if (key === rubric) {
          postRubrics.push({ headLine: Rubrics[key][lang], path: key });
        }
      });
    });
  }


  function* processData(Ast) {
    if (!Ast) {
      return;
    }

    for (let i = 0; i < Ast.length; i++) {
      const node = Ast[i];
      yield node.tagName;

      if (node.children) {
        yield* processData(node.children);
      }
    }
  }

  const it = processData(htmlAst.children);
  let res = it.next();

  for (; ;) {
    if (res.value === 'h1' || res.done) {
      break;
    }
    res = it.next();
  }

  const postfix = disableDescriptionPostfix ? '' : ` ${ARTICLES_DESCRIPTION[lang]}`;

  const metaTitle = title ? title :
    `${headLine} - ${TEMPLATE_TOKENS[lang].articles} – ${GLOBAL_CREDIT}`;
  const metaDescription = desc ? desc :
    `${GLOBAL_CREDIT} - ${headLine}${postfix}`;

  const year = date.slice(0, 4);
  const number = date.slice(8, 10);
  const month = MONTHS[lang][+date.slice(5, 7) - 1];
  const fotmatDate = `${number} ${month} ${year}`;

  return (
    <div>
      <Seo
        title={metaTitle}
        rating={frontmatter.rating ? frontmatter.rating : 4.5}
        path={path}
        lang={lang}
        image={frontmatter.image ? frontmatter.image.childImageSharp.resize.src : ''}
        date={date}
        category='stati'
        description={metaDescription}
        canonicalUrl={canonicalUrl}
        disableTitlePostfix={disableTitlePostfix}
      />
      <Breadcrumbs
        category={PAGES.article[lang]}
        post={headLine}
        postPath={path}
        categoryPath={`${lang === 'ru' ? '' : '/uk'}/category/stati`}
      />
      {!!postRubrics.length &&
        <div className='rubrics'>
          <p className='rubrics-title'>Рубрики: </p>
          {postRubrics.map(({ headLine, path }) => (
            <TagPage
              key={path}
              path={`${lang === 'ru' ? '' : '/uk'}/novosti/tag/${path}`}
              headLine={headLine}
            />
          ))}
        </div>
      }
      <div className='article-container'>
        <div className='article'>
          <p className='news-date'>{fotmatDate}</p>
          {!!res.value === 'h1' && <h1>{headLine}</h1>}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Voting
            rating={frontmatter.rating ? frontmatter.rating : 4.5}
            path={path}
          />
        </div>
        <Sidebar
          stocks
          news
          articles
          calculator
        />
      </div>
      <PopularPages />
    </div>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      htmlAst
      frontmatter {
        title
        headLine
        path
        category
        lang
        desc
        canonicalUrl
        disableTitlePostfix
        disableDescriptionPostfix
        date
        rubrics
        rating
        image {
          childImageSharp {
            resize(width: 350, height: 220) {
              src
            }
          }
        }
      }
    }
  }
`;

export default StockPost;


StockPost.propTypes = {
  data: PropTypes.object
};

